<template>
  <!-- v-touch="{
      left: () => $router.go(1),
      right: () => $router.push(-1)
    }" -->
  <v-row
    align="center"
  >
    <v-col>
      <app-card>
        <v-card-text>
          <h1>
            {{ $t("message.about_this_site") }}
          </h1>
          <br>
          <v-divider />
          <br>
          <h4>
            {{ $t("message.help_1") }}<br>
            {{ $t("message.help_2") }}
          </h4>
          <material-alert
            color="info"
            dark
          >
            <h3> {{ $t("message.help_7") }} </h3>
          </material-alert>
          <h4>
            {{ $t("message.help_8") }}<br>
            {{ $t("message.help_9") }}<br>
            <br>
            {{ $t("message.help_9_1") }}<br>
            <a
              href="https://twitter.com/ff14housingeden"
            >   <v-icon
              color="#55acee"
            >
              mdi-twitter
            </v-icon>
              @ff14housingeden
            </a><br>
            <br>
            {{ $t("message.help_10") }}<br>
            {{ $t("message.help_11") }}<br>
            {{ $t("message.help_12") }}<br>
            <br>
            {{ $t("message.help_13") }}<br>
            {{ $t("message.help_14") }}<br>
            {{ $t("message.help_15") }}<br>
          </h4>
          <material-alert
            color="info"
            dark
          >
            <h3> {{ $t("message.help_3") }} </h3>
          </material-alert>
          <h4>
            {{ $t("message.help_4") }}
          </h4>

          <material-alert
            color="info"
            dark
          >
            <h3> {{ $t("message.help_5") }} </h3>
          </material-alert>
          <h4>
            {{ $t("message.help_6") }}
          </h4>

          <material-alert
            color="info"
            dark
          >
            <h3> {{ $t("message.help_22") }}</h3>
          </material-alert>
          <h4>
            {{ $t("message.help_23") }}<br>
            {{ $t("message.help_24") }}<br>
            {{ $t("message.help_25") }}<br>
            {{ $t("message.help_26") }}<br>
            <br>
            {{ $t("message.help_27") }}<br>
            {{ $t("message.help_28") }}<br>
            {{ $t("message.help_29") }}<br>
            <br>
            {{ $t("message.help_30") }}<br>
            {{ $t("message.help_31") }}<br>
            {{ $t("message.help_32") }}<br>
          </h4>

          <material-alert
            color="info"
            dark
          >
            <h3> {{ $t("message.help_33") }} </h3>
          </material-alert>
          <h4>
            {{ $t("message.help_34") }}<br>
            <br>
            {{ $t("message.help_35") }}<br>
            <CommissionOpen />
            <br><br>
            {{ $t("message.help_36") }}<br>
            <CopyCommissionOpen />
            <br><br>
            {{ $t("message.help_37") }}<br>
            <CommissionClosed />
            <br><br>
            {{ $t("message.help_38") }}<br>
            <CommissionDel />
            <br><br>
            {{ $t("message.help_39") }}<br>
            {{ $t("message.help_40") }}<br>
          </h4>

          <material-alert
            color="info"
            dark
          >
            <h3> {{ $t("message.help_41") }} </h3>
          </material-alert>
          <h4>
            {{ $t("message.help_42") }}<br>
            {{ $t("message.help_43") }}<br>
          </h4>

          <material-alert
            color="info"
            dark
          >
            <h3> {{ $t("message.help_16") }} </h3>
          </material-alert>
          <h4>
            {{ $t("message.help_17") }}<br>
            {{ $t("message.help_18") }}<br>
            {{ $t("message.help_19") }}<br>
            {{ $t("message.help_20") }}<br>
            {{ $t("message.help_21") }}<br>
          </h4>
        </v-card-text>
      </app-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'NotificationsView',
    metaInfo: {
      title: 'Help',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: 'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden' },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://e-d-e-n.site' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        { name: 'description', content: 'FFXIV Housing Edenはファイナルファンタジー14の最新ハウジング情報を世界レベルでお届けするサイトです。今話題のツイートはもちろん、バズツイート、ハウジンガー名鑑、創作家具レシピまで網羅しています。' },
        { property: 'og:image', content: 'https://e-d-e-n.site/twitter_card.jpg?3' },
      ],
    },
    data () {
      return {
        // sns拡散
        sns: {
          twitter: 'https://twitter.com/intent/tweet?hashtags=HousingEden',
        },
      }
    },
  }
</script>
